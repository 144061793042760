<template>
  <validation-observer ref="simpleRules">
    <b-card-code
      title="Form Disposisi"
      :class="$route.name == 'detail-disposisi' ? 'detail-dispo' : ''"
    >
      <b-button
        v-show="$route.name === 'detail-disposisi' && authorize"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        style="position: absolute;right: 15px;top: -10px;"
        type="submit"
        @click.prevent="goEdit"
      >
        <span class="align-middle">Edit Disposisi</span>
      </b-button>
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="No. Surat"
            rules="required"
          >
            <b-form-group
              label="No. Surat"
              label-for="NoSurat"
            >

              <b-form-input
                id="NoSurat"
                v-model="NoSurat"
                placeholder="Input No. Surat"
                disabled
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- <b-form-group
            label="Deadline"
            label-for="Deadline"
          >
            <b-form-select
              v-model="Deadline"
              :options="optionsDeadline"
              placeholder="Pilih Deadline2"
            />
          </b-form-group> -->
          <b-form-group
            label="Deadline"
            label-for="Deadline"
          >
            <b-form-datepicker
              id="date-datepicker"
              v-model="Deadline"
              locale="id"
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="Perihal"
                placeholder="Input Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <b-form-select
                v-model="Kategori"
                :options="optionsKategori"
                placeholder="Pilih Kategori"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Pengirim"
            rules="required"
          >
            <b-form-group
              label="Pengirim"
              label-for="Pengirim Surat"
            >
              <b-form-input
                id="Pengirim"
                v-model="Pengirim"
                placeholder="Input Pengirim Surat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-form-group
            label="Keputusan"
            label-for="Keputusan"
            class="mt-1"
          >
            <v-select
              v-model="selectedKeputusan"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih Keputusan"
              :options="optionsKeputusan"
              :disabled="$route.name !== 'edit-disposisi'"
            />
          </b-form-group>

          <b-form-group
            class="mt-2"
            label-for="dilihat"
            label="Divisi/ Bagian/Unit Terkait"
          >
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              placeholder="Pilih Divisi"
              :options="options"
              :disabled="$route.name !== 'edit-disposisi'"
            />
          </b-form-group>

          <b-form-group
            label="Untuk Diperhatikan"
            label-for="dilihat"
            class="mt-2"
          >
            <v-select
              v-model="selectedViewers"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih Viewers"
              :options="optionsViewers"
              :disabled="$route.name !== 'edit-disposisi'"
              multiple
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="FileSurat"
            rules="required"
            class=" mt-2"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >

              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
                :disabled="$route.name !== 'edit-disposisi'"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">

          <validation-provider
            #default="{ errors }"
            name="Jenis Surat"
            rules="required"
            class="mb-2"
          >
            <b-form-group
              label="Jenis Surat"
              label-for="JenisSurat"
            >
              <b-form-select
                v-model="JenisSurat"
                :options="optionsJenisSurat"
                placeholder="Jenis Surat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <label for="textarea-default">Deskripsi</label>
          <b-form-textarea
            id="textarea-default"
            v-model="Deskripsi"
            placeholder="Textarea"
            rows="3"
          />

          <label
            for="textarea-default"
            class="mt-2"
          >Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="Catatan"
            placeholder="Textarea"
            rows="3"
          />

          <b-form-group
            label="Private"
            label-for="private"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="privates"
              checked="false"
              name="check-button"
              :disabled="$route.name !== 'edit-disposisi'"
              switch
              inline
            />
          </b-form-group>

        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="12">
          <h5> File Disposisi</h5>
          <b-form-group label-for="FileSurat">
            <b-row
              v-if="gantiFile == false && file !== null"
              class="match-height"
            >
              <b-col
                v-for="item in file"
                :key="item.access_url"
                md="6"
                class="fileSurat"
              >
                <div
                  class="open-file"
                  @click="openFile(item.access_url)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="56"
                  />
                  <h5 class="ml-1">
                    Open File
                    <span> {{ item.real_filename }}{{ item.ext }} </span>
                  </h5>
                </div>
              </b-col>

              <b-button
                v-show="$route.name === 'edit-disposisi'"
                v-model="gantiFile"
                variant="outline-primary"
                class="bg-gradient-primary mt-1 ml-1"
                style="height: 29px"
                size="sm"
                @click.prevent="toggleFile"
              >
                <span class="align-middle">Ganti File</span>
              </b-button>
            </b-row>

            <b-row v-else>
              <b-col md="6">
                <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;">
                  <b-form-file
                    id="FileSurat"
                    ref="file"
                    v-model="file"
                    type="file"
                    placeholder="Input File Surat"
                    drop-placeholder="Drop file here..."
                    :disabled=" $route.name !== 'edit-disposisi'"
                    multiple="multiple"
                    @change="fileChange"
                  />

                  <b-button
                    v-show="file !== null"
                    v-model="gantiFile"
                    variant="outline-primary"
                    class="bg-gradient-primary mt-1"
                    @click.prevent="toggleFile"
                  >
                    <span class="align-middle">Open File</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form-group>

        </b-col>
      </b-row>

      <b-button
        v-show="$route.name == 'edit-disposisi'"
        variant="outline-primary"
        class="bg-gradient-primary"
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>

    </b-card-code>

    <b-card-code
      title="Komentar Disposisi"
      :class="$route.name == 'detail-disposisi' ? 'detail-dispo' : ''"
    >
      <b-row
        class="match-height"
        style="padding:0 30px"
      >
        <b-col md="12">
          <h5 class="mb-1">
            Keputusan
          </h5>
          <div class="tanggapan">
            <div class="avatar">
              <img
                v-if="selectedKeputusan.avatar === ''"
                :src="require('@/assets/images/icons/user.png')"
              >
              <img
                v-else
                :src="selectedKeputusan.avatar"
              >
            </div>
            <div
              class="input"
              :class="userRole === selectedKeputusan.role_id ? '' : 'uncomment'"
            >
              <div class="komentar-title">
                <h2> {{ selectedKeputusan.text }} </h2>
                <span> {{ komentar1Date ? komentar1Date : '' }} </span>
              </div>
              <b-input-group>
                <div class="komentar">
                  <div class="left">
                    <b-form-input
                      v-model="Komentar1"
                      :disabled="userRole !== selectedKeputusan.role_id"
                      :placeholder="userRole !== selectedKeputusan.role_id ? 'Belum ada komentar' : 'Tulis Komentar Anda'"
                    />
                  </div>
                  <div class="right">
                    <b-button
                      v-show="userRole === selectedKeputusan.role_id"
                      variant="outline-primary"
                      class="bg-gradient-primary btn-sm"
                      style="font-size: 12px;height:unset"
                      @click="kirimKomentar1"
                    >
                      <span class="align-middle"> {{ userRole !== selectedKeputusan.role_id ? 'Tambah Komentar' : 'Update Komentar' }}</span>
                    </b-button>
                  </div>
                </div>
              </b-input-group>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <h5 class=" mt-3 mb-1">
            Responder
          </h5>
          <b-row>
            <b-col
              v-for="(option, index) in Komentar"
              :key="option.id"
              md="6"
              class="tanggapan mb-2"
            >
              <div class="avatar">
                <img
                  v-if="option.avatar === ''"
                  :src="require('@/assets/images/icons/user.png')"
                >
                <img
                  v-else
                  :src="option.avatar"
                >
              </div>
              <div
                class="input"
                :class="userRole === option.role_id ? '' : 'uncomment'"
              >
                <div class="komentar-title">
                  <h2> {{ option.role_name }} - {{ option.user_name }} </h2>
                  <span> {{ dateFormat(option.updated_at) }} </span>
                </div>
                <b-input-group>
                  <div class="komentar">
                    <div class="left">
                      <b-form-input
                        v-model="Komentar[index].comment"
                        :placeholder="userRole === option.role_id ? 'Tambahkan Komtentar Anda' : 'Belum ada komentar'"
                        :disabled="userRole !== option.role_id"
                      />
                    </div>
                    <div class="right">
                      <b-button
                        v-show="userRole === option.role_id"
                        variant="outline-primary"
                        class="bg-gradient-primary btn-sm"
                        style="font-size: 12px;height:unset"
                        @click="kirimKomentar(index)"
                      >
                        <span class="align-middle"> {{ option.updated_at ? 'Update Komentar' : 'Tambah Komentar' }}</span>
                      </b-button>
                    </div>
                  </div>
                </b-input-group>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-code>

    <b-card-code
      v-show="authorizeResponder"
      title="Responder Disposisi"
    >
      <b-row class="match-height">
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="selected2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              :close-on-select="false"
              placeholder="Pilih Divisi"
              :options="options"
              style="width: 100%;"
            />
            <b-button
              variant="outline-primary"
              class="bg-gradient-primary btn-sm mt-1"
              style="font-size: 12px;height: 37px;"
              @click="addResponder()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Simpan
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>

    <b-row class="match-height">
      <b-col md="7">
        <b-card-code
          title="Status Disposisi"
          style="max-height: 200px;"
        >
          <v-select
            v-model="selectedPerintah"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :disabled="!authorize"
            placeholder="Pilih Status"
            :options="[{value: 1, text: 'Open' }, {value: 2, text: 'Close' }]"
            class="mb-2"
          />
          <b-button
            v-b-modal.modal-primary
            :disabled="selectedPerintah.length === 0 || !authorize"
            variant="outline-primary"
            class="bg-gradient-primary mt-2"
            type="submit"
            @click="modal =!modal"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Simpan</span>
          </b-button>
        </b-card-code>
      </b-col>

      <b-col md="5">
        <b-card-code
          title="Riwayat Disposisi"
          class="activity"
        >
          <app-timeline
            v-for="data in activity"
            :key="data.id"
          >
            <app-timeline-item
              :title="data.user.name"
              :subtitle="data.message"
              :time="dateFormat(data.updated_at)"
              variant="info"
              style="padding-bottom:20px"
            />
          </app-timeline>
        </b-card-code>
      </b-col>
    </b-row>

    <b-row
      v-show="selectedPerintahFinal.text === 'Close' && authorize"
      class="match-height"
      style="justify-content: center;"
    >
      <b-col md="6">
        <!-- <b-card-code title="Riwayat Disposisi"> -->
        <b-button
          v-b-modal.modal-perintah
          :disabled="selectedPerintah.length === 0"
          variant="outline-primary"
          class="bg-gradient-primary mt-2"
          type="submit"
          @click="modal =!modal"
        >
          <span class="align-middle">Kirim Perintah</span>
        </b-button>
        <!-- </b-card-code> -->
      </b-col>
    </b-row>

    <!-- modal -->
    <b-modal
      id="modal-primary"
      v-model="modal"
      ok-title="Lanjutkan"
      modal-class="modal-primary"
      cancel-variant="outline-secondary"
      cancel-title="Kembali"
      centered
      title="Proses Dispoisi"
      no-stacking
      @ok="handleOk()"
    >
      <b-card-text>
        Lanjutkan proses <b> {{ selectedPerintah.text }} </b> disposisi ?
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-perintah"
      v-model="modalPerintah"
      ok-title="Simpan"
      modal-class="modal-primary"
      cancel-variant="outline-secondary"
      cancel-title="Kembali"
      size="lg"
      centered
      title="Tambah Perintah"
      @ok="validationFormPerintah()"
    >
      <b-row class="match-height">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="PerihalPerintah"
                placeholder="Tulis Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="Tanggal"
            >
              <b-form-datepicker
                id="date-Perintah"
                v-model="startDatePerintah"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
            class="mb-2"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <b-form-select
                v-model="KategoriPerintah"
                :options="optionsKategori"
                placeholder="Pilih Kategori"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-form-group
            label="File Disposisi"
            label-for="FileSurat"
          >
            <!-- Styled -->
            <b-form-file
              id="FileSurat"
              ref="file"
              v-model="filePerintah"
              type="file"
              placeholder="Tambah File Disposisi"
              drop-placeholder="Drop file here..."
              multiple="multiple"
              @change="fileChangePerintah"
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="Deskripsi"
            class="mt-1 "
          >
            <label for="textarea-default">Deskripsi</label>
            <b-form-textarea
              id="textarea-default"
              v-model="DeskripsiPerintah"
              placeholder="Tulis Deskripsi"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Deadline"
            rules="required"
          >
            <span
              v-show="startDatePerintah === null "
              class="warning"
            > Pilih Tanggal terlebih dahulu </span>
            <b-form-group
              label="Deadline"
              label-for="Deadline"
            >
              <b-form-datepicker
                id="deadline-Perintah"
                v-model="DeadlinePerintah"
                :min="startDatePerintah"
                :disabled="startDatePerintah == null"
                locale="id"
                class="mb-1"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-form-group
            label="PIC"
            label-for="PIC"
            class="mb-2"
          >
            <v-select
              v-model="PICPerintah"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih PIC"
              :options="options"
            />
          </b-form-group>

          <b-form-group
            label="Divisi/ Bagian/Unit Terkait"
            label-for="selected"
            class="mb-2"
          > <span
              v-show="PICPerintah.length === 0"
              class="warning"
            > Pilih PIC terlebih dahulu </span>
            <v-select
              v-model="ResponderPerintah"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :disabled="PICPerintah.length === 0"
              multiple
              placeholder="Pilih Divisi"
              :close-on-select="false"
              :options="options"
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="FileSurat"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tagsPerintah"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-col>
      </b-row>
    </b-modal>
  </validation-observer>
</template>

<script>
/* eslint-disable no-unneeded-ternary */
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BInputGroup,
  BFormCheckbox,
  VBModal,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import axios from '@axios'
import dayjs from 'dayjs'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    BInputGroup,
    vSelect,
    BFormCheckbox,
    VBModal,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      AllData: [],
      NoSurat: '',
      Deadline: null,
      modal: false,
      modalPerintah: false,
      Perihal: '',
      Kategori: '',
      Pengirim: '',
      Deskripsi: '',
      Catatan: '',
      Perintah: '',
      Jabatan: '',
      Komentar1: '',
      komentar1Date: '',
      dispoID: '',
      Komentar: [],
      Responder1: '',
      userRole: '',
      selectedFile: '',
      JenisSurat: '',
      selectedKeputusan: [],
      selectedPerintah: [],
      selectedPerintahFinal: [],
      selectedViewers: [],
      activity: [],
      privates: false,
      authorize: false,
      authorizeResponder: false,
      file: [],
      fileID: [],
      gantiFile: false,
      fileName: '',
      url: '',
      selected: [],
      selected2: [],
      options: [],
      userName: '',
      optionsResponder: [],
      optionsKeputusan: [],
      userData: [],
      optionsViewers: [{ value: '', text: '' }],
      tags: [],
      optionsJenisSurat: [
        { value: '', text: 'Pilih Jenis Surat' },
        { value: 'Bisnis', text: 'Bisnis' },
        { value: 'Organisasi', text: 'Organisasi' },
        { value: 'CSR', text: 'CSR' },
      ],
      optionsDeadline: [
        { value: 1, text: 'Satu Hari' },
        { value: 2, text: 'Dua Hari' },
        { value: 3, text: 'Tiga hari' },
      ],
      optionsKategori: [
        { value: '', text: 'Pilih Kategori' },
        { value: 'Undangan', text: 'Undangan' },
        { value: 'Permohonan', text: 'Permohonan' },
        { value: 'CSR', text: 'CSR' },
        { value: 'Instansi Pendidikan', text: 'Instansi Pendidikan' },
        { value: 'Pemberitahuan', text: 'Pemberitahuan' },
        { value: 'ILain-lain', text: 'ILain-lain' },
      ],
      optionsPerintah: [
        { value: '', text: 'Pilih Tujuan' },
        { value: '1', text: 'Manager Umum' },
        { value: '2', text: 'Kabag HRD' },
        { value: '3', text: 'Manajer Divisi Inti' },
        { value: '4', text: 'Kabag Keuangan' },
      ],

      /* Perintah */
      PerihalPerintah: '',
      KategoriPerintah: '',
      DeskripsiPerintah: '',
      startDatePerintah: null,
      DeadlinePerintah: null,
      filePerintah: [],
      filesPerintah: [],
      tagsPerintah: [],
      selectedTujuan: [],
      PICPerintah: [],
      ResponderPerintah: [],
    }
  },
  created() {
    this.authorize = store.state.userData.data.is_admin === 1
    this.userRole = store.state.userData.data.role.id
  },
  mounted() {
    this.getResponder()
    this.getDetail()
    this.getViewer()
    this.getDecision()
    // this.getActivity()
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Disposition',
        },
      })
      this.file = data.data
    },
    async fileChangePerintah(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Assignment',
        },
      })
      this.filePerintah = data.data
    },
    openFile(e) {
      window.open(e, '_blank')
    },
    goEdit() {
      window.location.href = `/disposisi/edit-disposisi/${this.NoSurat}`
    },
    toggleFile() {
      if (this.gantiFile === false) {
        this.gantiFile = true
      } else {
        this.gantiFile = false
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.editDispo()
        }
      })
    },
    async kirimKomentar1() {
      await axios
        .post(`siap/disposition/comment/${this.NoSurat}`, {
          comment: this.Komentar1,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDetail()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY (HH:mm)')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },
    async kirimKomentar(n) {
      await axios
        .post(`siap/disposition/comment/${this.NoSurat}`, {
          comment: this.Komentar[n].comment,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDetail()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    validationFormPerintah() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addAssignment()
        }
      })
    },

    async addAssignment() {
      const responder = this.ResponderPerintah.map(e => ({
        user_id: e.user_id,
        role_id: e.value,
        receiver_type: e.receiver_type,
      }))
      await axios
        .post('siap/assignment/create', {
          feature: 'Disposition',
          feature_ref_id: this.AllData.id,
          feature_ref_num: this.AllData.ref_num,
          title: this.PerihalPerintah,
          cat: this.KategoriPerintah,
          desc: this.DeskripsiPerintah,
          start_date: dayjs(this.startDatePerintah).format(
            'YYYY-MM-DDTHH:mm:ss+07:00',
          ),
          deadline: dayjs(this.DeadlinePerintah).format(
            'YYYY-MM-DDTHH:mm:ss+07:00',
          ),
          file: this.filePerintah,
          tags: this.tagsPerintah,
          send_to: responder.concat({
            user_id: this.PICPerintah.user_id,
            role_id: this.PICPerintah.value,
            receiver_type: 'PIC',
          }),
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // this.$router.push({ name: `detail-disposisi/${response.data.data.id}` })
          // window.location.href = `detail-disposisi/${response.data.data.id}`
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getDetail() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/disposition/detail/${param}`)
      this.AllData = data
      this.NoSurat = data.ref_num
      this.Deadline = data.deadline.date
      this.Perihal = data.title
      this.dispoID = data.id
      this.Pengirim = data.from
      this.Deskripsi = data.desc
      this.JenisSurat = data.type
      this.Catatan = data.note
      this.file = data.file
      this.selectedPerintah = {
        value: data.status.id,
        text: data.status.name,
      }
      this.selectedPerintahFinal = {
        value: data.status.id,
        text: data.status.name,
      }
      this.Kategori = data.category.name
      this.Komentar1 = data.detail.decision.comment
      this.komentar1Date = data.detail.decision.updated_at === null
        ? ''
        : dayjs(data.detail.decision.updated_at).format('DD-MM-YYYY - HH:mm')
      this.Komentar = data.detail.responder
      if (data.detail.responder !== null) {
        this.selected = data.detail.responder.map(e => ({
          value: e.role_id,
          text: `${e.role_name} - ${e.user_name}`,
          user_id: e.user_id,
          role_id: e.role_id,
          avatar: e.avatar,
          receiver_type: 'Responder',
        }))
      }
      this.selected2 = this.selected
      if (data.detail.guest !== null) {
        this.selectedViewers = data.detail.guest.map(e => ({
          value: e.role_id,
          text: `${e.role_name} - ${e.user_name}`,
          user_id: e.user_id,
          role_id: e.role_id,
          avatar: e.avatar,
          receiver_type: 'Guest',
        }))
      }
      this.selectedKeputusan = {
        value: data.detail.decision.role_id,
        text: `${data.detail.decision.role_name} - ${data.detail.decision.user_name}`,
        user_id: data.detail.decision.user_id,
        role_id: data.detail.decision.role_id,
        avatar: data.detail.decision.avatar,
        receiver_type: 'Decision',
      }
      if (this.selected.find(e => e.user_id === store.state.userData.data.id)) {
        this.authorizeResponder = true
      } else if (
        this.selectedKeputusan.user_id === store.state.userData.data.id
      ) {
        this.authorizeResponder = true
      } else {
        this.authorizeResponder = false
      }
      this.privates = data.is_private ? 'Yes' : 'No'
      this.tags = data.tag.map(e => e.name)
      this.getActivity()
      // .catch(error => {
      //   console.log(error)
      // })
    },

    async getResponder() {
      const { data } = await axios.get('siap/account/receiver-type', {
        params: {
          feature: 'Disposition',
          show_all: 'true',
        },
      })
      this.options = data.map(e => ({
        value: e.role.id,
        text: `${e.role.name} - ${e.user.name}`,
        user_id: e.user.id,
        role_id: e.role.id,
        avatar: e.user.avatar,
        receiver_type: 'Responder',
      }))
    },

    async getViewer() {
      const { data } = await axios.get('siap/account/receiver-type', {
        params: {
          feature: 'Disposition',
          show_all: 'true',
        },
      })
      this.optionsViewers = data.map(e => ({
        value: e.role.id,
        text: `${e.role.name} - ${e.user.name}`,
        user_id: e.user.id,
        role_id: e.role.id,
        avatar: e.user.avatar,
        receiver_type: 'Guest',
      }))
    },

    async getDecision() {
      const { data } = await axios.get('siap/account/receiver-type', {
        params: {
          feature: 'Disposition',
          show_by_code: 'A,B',
        },
      })
      this.optionsKeputusan = data.map(e => ({
        value: e.role.id,
        text: `${e.role.name} - ${e.user.name}`,
        user_id: e.user.id,
        role_id: e.role.id,
        avatar: e.user.avatar,
        receiver_type: 'Decision',
      }))
    },

    async getActivity() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/disposition/activity/${param}`)
      this.activity = data.data
    },

    async handleOk() {
      await axios.post(`siap/disposition/update/status/${this.NoSurat}`, {
        status: this.selectedPerintah.text,
      })
      this.getDetail()
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async addResponder() {
      const result = this.selected2.filter(
        i => !this.selected.map(v => v.user_id).includes(i.user_id),
      )
      await axios
        .post(`siap/disposition/add-receiver-type/${this.NoSurat}`, {
          send_to: result,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
      this.getDetail()
    },

    async editDispo() {
      const responder = this.selected.map(e => ({
        user_id: e.user_id,
        role_id: e.value,
        receiver_type: e.receiver_type,
      }))
      const viewers = this.selectedViewers.map(e => ({
        user_id: e.user_id,
        role_id: e.value,
        receiver_type: e.receiver_type,
      }))
      await axios
        .post(`siap/disposition/update/${this.NoSurat}`, {
          title: this.Perihal,
          from: this.Pengirim,
          cat: this.Kategori,
          desc: this.Deskripsi,
          deadline: 3,
          note: this.Catatan,
          type: this.JenisSurat,
          file: this.file,
          tags: this.tags,
          is_private: this.privates ? 'Yes' : 'No',
          send_to: viewers
            .concat({
              user_id: this.selectedKeputusan.user_id,
              role_id: this.selectedKeputusan.value,
              receiver_type: this.selectedKeputusan.receiver_type,
            })
            .concat(responder),
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
      this.getDetail()
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}

.detail-dispo {
  .form-control,
  .custom-select {
    border: unset;
    border-bottom: 1px solid #d8d6de;
    border-radius: 0;
    pointer-events: none;
  }
}

.fileSurat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}

.tanggapan {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .komentar-title {
    display: flex;
    align-items: baseline;
    span {
      font-size: 13px;
      margin-left: 10px;
    }
  }
  .komentar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .left {
      flex-grow: 1;
      margin-right: 10px;
      span {
        font-size: 14px;
        display: block;
        flex-grow: 1;
        margin-right: 10px;
      }
    }
  }
  .uncomment {
    input {
      border: unset !important;
      // border-left: solid 1px !important;
      border-radius: 0 !important;
      padding-left: 0;
      background: #fff;
      color: #5e5873;
    }
  }
  input {
    border: 1px solid #d8d6de !important;
    pointer-events: unset !important;
  }
  .avatar {
    width: 70px;
    height: 70px;
    border: solid 1px #9e9e9e;
    img {
      width: 100%;
      height: intrinsic;
      object-fit: cover;
    }
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  .input {
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append {
    cursor: pointer;
  }
  .input-group-text {
    background-image: linear-gradient(47deg, #00427a, #005ead);
    color: #fff;
  }
  .btn {
    height: 38px;
  }
}

[dir] .vs--disabled .vs__selected {
  background-color: #00427a;
  color: #fff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #615f6f;
}

.open-file {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover {
    background: #efefef;
  }
}
.form-group {
  position: relative;
}
.warning {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: 0;
  right: 15px;
}

.activity {
  .card-body {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
